import React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import {ProductPlaceholder as ProductPlaceholderIcon} from '../../../icons/dist';
import s from './ProductPlaceholder.scss';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

@withGlobalProps
@withTranslations()
export class ProductPlaceholder extends React.Component<IProvidedGlobalProps & IProvidedTranslationProps> {
  public render() {
    return (
      <div className={s.root} data-hook="product-placeholder">
        <div>
          <ProductPlaceholderIcon size="70px" data-hook="product-placeholder-image" />
        </div>
        <p className={s.text} data-hook="product-placeholder-text">
          {this.props.t('sliderGalleryNoProductsMessageText')}
        </p>
      </div>
    );
  }
}
